import { Box, Grid, Hidden, makeStyles, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const Cotacao: React.FC = () => {
	const theme = useTheme()
	const matSm = useMediaQuery(theme.breakpoints.up('sm'))
	const matMd = useMediaQuery(theme.breakpoints.up('md'))
	const styles = useStyle()	
	const [os, setOs] = useState('')
	
	useEffect(()=>{
		setOs(window.navigator.platform)
	},[])

	return (
		<Box
			paddingX={(matSm) ? 5 : 1}
			marginTop={-10}
			marginBottom={(matMd) ? -36 : (matSm) ? -10 : -5}
		>
			<Paper elevation={4}>
				<Box
					padding={(matSm) ? 5 : 1}
				>
					<Grid container wrap='wrap' spacing={1}>
						<Grid item md={5} sm={12} xs={12}>
							<Box
								height='70%'
								minHeight='200px'
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
								textAlign='center'
								paddingX={2}
								className={(os !== 'Linux armv7l') ? styles.BoxTitle : styles.BoxTitleIOS }
								color={theme.palette.primary.contrastText}
							>
							<Typography variant='h4'>
								Faça sua cotação agora!
							</Typography>
							<Hidden xsDown>
								<Typography variant='h5'>
									Nunca foi tão <strong>fácil</strong> enviar remessas ao exterior
								</Typography>
							</Hidden>
							<Typography variant='h6'>
								Mais de <strong>15</strong> moedas <br/> Mais de <strong>20 anos</strong> de experiêcia
							</Typography>
							</Box>
						</Grid>

						<Grid item md={7} sm={12} xs={12}>
							<Paper elevation={3}>
								<Box className={styles.Iframe}>
									<iframe src="https://iamsimple.com.br/banco-sol?paper-money=true" title="Câmbio" scrolling='no'></iframe>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Box>
	);
}

const useStyle = makeStyles({
	Iframe: {
		minWidth: '100%',
		//maxWidth: 600,
		height: 1050,
		overflowY: 'hidden',
		borderRadius: 'inherit',
		//border: 'solid 1px #011e3b',
		boxShadow: 'none',

		'& iframe': {
			height: 1100,
			minWidth: '100%',
			border: 'none',
		},

		'@media (max-width: 455px)' : {
			height: 1060,

			'@media (max-width: 352px)' : {
				height: 1120
			}
		}
	},

	BoxTitle: {
		backgroundImage: 'url(/assets/images/banner.jpg)',
		backgroundSize: 'cover',
		backgroundAttachment: 'fixed',
		backgroundPosition: '600px 100px'
	},

	BoxTitleIOS: {
		backgroundImage: 'url(/assets/images/banner.jpg)',
		backgroundSize: 'cover',
		backgroundPosition: '600px 100px'
	}
})

export default Cotacao;

/*
div.iframe {
		width: 100%;
		max-width: 600px;
		height: 90%;
		max-height: 1500px;
		overflow-y: hidden;
		border-radius: 16px;
		border: solid 2px ${p => p.theme.cores.primaria};

		iframe {
			height: 1200px;
			width: 100%;
			border: none;
			box-shadow: none;
		}
	}
*/
