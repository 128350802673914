import React from 'react';
import Banner from '../components/cambio/banner';
import Cotacao from '../components/cambio/cotacao';
import ParaEmpresa from '../components/cambio/ParaEmpresa';
import Parceiros from '../components/cambio/Parceiros';
import Container from '../components/estrutura/Container';

// import { Container } from './styles';

const Home: React.FC = () => {
  return (
    <Container>
      <Banner />
      <Cotacao />
      <ParaEmpresa />
      <Parceiros />      
    </Container>
  );
}

export default Home;