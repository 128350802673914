import { createTheme, responsiveFontSizes } from '@material-ui/core'

const muiTheme = responsiveFontSizes(createTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'#megasac-info': {
					display: 'none !important',
				},
				'*': {
					outline: 'none',
				},
			}
		}
	},
	palette: {
		primary: {
			main: '#2f1f3d',
		},
		secondary: {
			main: '#dbb42c',
		},
	},
	typography: {
		fontFamily: 'Roboto Condensed',
		h1: {
			fontFamily: 'Ubuntu',
		},
		h2: {
			fontFamily: 'Ubuntu',
		},
		h3: {
			fontFamily: 'Ubuntu',
		},
		h4: {
			fontFamily: 'Ubuntu',
		},
		h5: {
			fontFamily: 'Ubuntu',
		},
		h6: {
			fontFamily: 'Ubuntu',
		},
	}
}))

export default muiTheme