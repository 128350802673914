import React from 'react';
import { Route, Routes as ReactRouter, BrowserRouter } from 'react-router-dom';
import Home from '../pages';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <ReactRouter>
        <Route path='/' element={<Home />} />
      </ReactRouter>
    </BrowserRouter>
  );
}

export default Router;