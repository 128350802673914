import { Box, Card, CardMedia, Grid, makeStyles, Typography, useTheme, CardContent, useScrollTrigger, Grow, Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

interface IGridCardProps {
	imgSrc: string,
	texto: string,
	titulo: string,
	inBoo: boolean,
	timeout: number,
}

const ParaEmpresa: React.FC = () => {
	const theme = useTheme()
	const styles = useStyles()
	const trig = useScrollTrigger({threshold: 500})
	const [animateCards, setAnimateCards] = useState(false)

	useEffect(() => {
		const cards = document.querySelector("#cards");
		const observer = new IntersectionObserver((item) => {
			if(item[0].intersectionRatio > 0 && !animateCards){
				setAnimateCards(true)
			}
		}, {});
		if(cards){
			observer.observe(cards);
		}
	},[])

	return (
		<Box
			width='100%'			
			height='max-content'
		>
			<Grid container className={styles.GridContainer}>
				<Grid item container xs={12}>
					<Grid item md={5} sm={12}>
						<Box
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
							height='100%'
							padding={5}
							color={theme.palette.primary.contrastText}
						>
							<Typography variant='h3'>
								Câmbio para sua empresa
							</Typography>
							<Typography variant='h5'>
								Conte com o <strong>Banco Sol Capital Digital</strong> para o sucesso dos seus negócios no exterior
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid item container xs={12} id='cards'>
					<Grid item xs={12}>
						<Box marginTop={4} padding={4} color={theme.palette.secondary.light}>
							<Typography variant='h4' align='center'>Conheça as <strong>vantagens de ser</strong> Sol Capital</Typography>
						</Box>
					</Grid>
					
					<GridCard inBoo={animateCards} timeout={2400} imgSrc='equipe.svg' titulo='' texto='Equipe qualificada para te atender' />
					<GridCard inBoo={animateCards} timeout={2800} imgSrc='solucoes.svg' titulo='' texto='Variedade de soluções' />
					<GridCard inBoo={animateCards} timeout={3200} imgSrc='atendimento.svg' titulo='' texto='Atendimento ágil e eficaz' />
					<GridCard inBoo={animateCards} timeout={3600} imgSrc='seguranca.svg' titulo='' texto='Segurança nas Operações' />
				</Grid>
			</Grid>
		</Box>
	);
}

const GridCard: React.FC<IGridCardProps> = ({ imgSrc, texto, titulo, inBoo, timeout }) => {
	const styles = useStyles()

	return (
		<Grow in={inBoo} timeout={timeout}>
			<Grid item md={3} sm={6} xs={12}>
				<Box padding={3}>
					<Card>
						<CardMedia
							component="img"
							alt="ico"
							height="140"
							image={`/cache/images/icons/${imgSrc}`}
							className={styles.CardMediaImg}
						/>
						<CardContent>
							<Box height={50}>
								<Typography variant="h5" component="h2">
									{titulo}
								</Typography>
								<Typography align='center' variant="body1" color="textPrimary" component="p">
									{texto}
								</Typography>
							</Box>
						</CardContent>
					</Card>
				</Box>
			</Grid>
		</Grow>
	)
}

const useStyles = makeStyles({
	GridContainer: {
		height: '100%',
		//background: 'url(/assets/images/banner02.jpg) linear-gradient(#4b4b63)',
		background: 'linear-gradient(#4b4b63F0, #4b4b63F6), url(/assets/images/banner02.jpg)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	CardMediaImg: {
		objectFit: 'contain',
		paddingTop: 8
	}
})

export default ParaEmpresa;