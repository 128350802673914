import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Container: React.FC = ({ children }) => {
	const theme = useTheme()

	return (
		<Box
			bgcolor={theme.palette.grey[300]}
		>
			<Header />
			{children}
			<Footer />
		</Box>
	);
}

export default Container;