import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';

// import { Container } from './styles';

const Parceiros: React.FC = () => {
  const theme = useTheme()

  return (
    <Box paddingX={4} paddingY={3} bgcolor={theme.palette.background.paper}>
      <Grid container alignItems='center' spacing={3}>
        <Grid item xs={12}>        
          <Box maxWidth='50%' minWidth={300} marginX='auto'>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={12}>        
          <Typography color='secondary' align='center' variant='h4'>Nossos Parceiros</Typography>                          
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box padding={5}>
            <img width='100%' src='/assets/images/logo-mb.png' alt='logo'/>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box padding={5}>
            <img width='100%' src='/assets/images/logo-c6.png' alt='logo'/>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box padding={5}>
            <img width='100%' src='/assets/images/logo-frente.png' alt='logo'/>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <img width='100%' src='/assets/images/logo-br.png' alt='logo'/>
        </Grid>
        <Grid item xs={12}>        
          <Box maxWidth='50%' minWidth={300} marginX='auto'>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Parceiros;