import { Box, makeStyles, Grid, Typography, useTheme, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const Banner: React.FC = () => {
	const styles = useStyles()
	const theme = useTheme()
	const [os, setOs] = useState('')
	
	useEffect(()=>{
		setOs(window.navigator.platform)
	},[])
	
  return (
		<Box
			height='84vh'
			width='100%'
			className={(os !== 'Linux armv7l') ? styles.Banner : styles.BannerIOS}
			display='flex'
			justifyContent='center'
			alignItems='center'
			padding={5}			
			
		>
			<Grid container spacing={5}>
				<Grid item md={4} sm={12}>					
					<img height='165' width='340' src='/assets/images/logo.png' alt='logo' />
				</Grid>
				<Grid item md={8} sm={12}>
					<Box 
						display='flex' 
						flexDirection='column' 
						justifyContent='space-between'
						textAlign='end'
						color={theme.palette.grey[100]}												
					>
						<Typography variant='h3' color='inherit' >
							Soluções cambiais 
							<Hidden xsDown>
								<span/> para <br/> sua empresa e sua família
							</Hidden>
						</Typography>						
						<Typography variant='h5' color='inherit' >Câmbio / Offshore</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

const useStyles = makeStyles({
	Banner: {
		backgroundImage: 'url(/assets/images/banner01.jpg)',
		backgroundSize: 'cover',
		backgroundAttachment: 'fixed'
	},

	BannerIOS: {
		backgroundImage: 'url(/assets/images/banner01.jpg)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: -400
	}
})

export default Banner;