import React from 'react';
import Routes from './router'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import theme from './styles/themes/muiTheme'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />    
      <Routes />
    </ThemeProvider>
  );
}

export default App;