import { Box, Grid, Hidden, Link, List, ListItem, ListItemText, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { FaInstagram, FaFacebook } from 'react-icons/fa'

const Footer: React.FC = () => {
	const theme = useTheme()
	const styles = useStyles(theme)	

  return (
    <Box
      bgcolor={theme.palette.primary.dark}
			padding={2}			
			minHeight='max-content'
			width='100%'
    >
			<Grid container justify='center' alignItems='center' className={styles.FooterGrid}>
				<Grid item md={4} sm={12} xs={12}>
					<Box width='100%' display='flex' justifyContent='center'>
						<img height='100' width='206' src='/assets/images/logo.png' alt='logo' />
					</Box>
				</Grid>				
				<Grid container item md={4} sm={12} xs={12}>
					<Box width='100%' display='flex' flexDirection='row' justifyContent='center' paddingTop={1}>					
						<Typography variant='subtitle1' align='center'>							
							Av. Presidente Antônio Carlos, 615, 4º andar - Rio de Janeiro<br />Capitais e demais regiões <br/>Tel.: + 55 (21) 3900-0144
						</Typography>			
					</Box>
				</Grid>
				<Grid container item md={4} sm={12} xs={12}>
					<Box width='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' paddingTop={1} color='#FFF'>
						<Link href='https://www.instagram.com/bancosolcapital/' target="_blank" color='inherit'>
							<FaInstagram size={36}/>
						</Link>
						<Box marginX={1} />
						<Link href='https://www.facebook.com/bancosolcapital/' target="_blank" color='inherit'>
							<FaFacebook size={36}/>
						</Link>
					</Box>
				</Grid>
			</Grid>
			<Box 
				bgcolor={theme.palette.primary.dark} 
				color={theme.palette.primary.contrastText} 
				width='100%' 
				display='flex'
				justifyContent='center'
				paddingTop={1}
			>
				<Typography variant='caption' align='center' color='inherit'>BBSC Digital, Ltda. © {new Date().getFullYear()}</Typography>
			</Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
	FooterGrid: {
		height: '100%',		
		color: theme.palette.primary.contrastText
	},
}))

export default Footer;