import { Grid, Hidden, IconButton, Toolbar, Box, ButtonGroup, Button, Drawer, AppBar, useScrollTrigger, makeStyles, Menu, MenuItem, useTheme, useMediaQuery } from '@material-ui/core'
import React, { useState } from 'react'
import { MdMenu } from 'react-icons/md'

const Header: React.FC = () => {
	const theme = useTheme()
	const [toggle, setToggle] = useState(false)
	const trigg = useScrollTrigger({ disableHysteresis: true, threshold: 200 })
	const matSm = useMediaQuery(theme.breakpoints.up('sm'))
	const styles = useStyles(theme)

	const handleDrawerToggle = () => {
		setToggle(!toggle)
	}

	return (
		<AppBar position='fixed' className={(trigg) ? styles.MenuVis : styles.MenuIvi}>
			<Toolbar>
				<Hidden smUp implementation='js'>
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
						<MdMenu />
					</IconButton>
				</Hidden>

				<Box paddingX={3} paddingY={1} width='100%' display='flex' flexDirection='row' alignItems='center'>
					<Hidden xsDown>
						<MenuNav />
					</Hidden>
				</Box>
			</Toolbar>
			<Hidden smUp implementation="js">
				<Drawer
					variant="temporary"
					anchor={"left"}
					open={toggle}
					onClose={handleDrawerToggle}
				>
					<Box maxWidth={200} padding={2} height='100%' bgcolor={theme.palette.primary.light}>
						<MenuNav />
					</Box>
				</Drawer>
			</Hidden>
		</AppBar>
	);
}

const MenuNav: React.FC = () => {
	const [menuToggle, setMenuToogle] = useState(false)
	const theme = useTheme()
	const matSm = useMediaQuery(theme.breakpoints.up('sm'))

	const toggle = () => {
		setMenuToogle(!menuToggle)
	}

	return (
		<Grid container alignItems='center' spacing={4}>
			<Grid container item xs={12} sm={8} md={4} alignItems='center' spacing={2}>
				<Grid item xs={12} sm={4} md={4} alignItems='center'>
					<Box display='flex' alignItems='center' justifyContent='center'>
						<img height={60} width={126} src='/assets/images/logo.png' alt='logo' />
					</Box>
				</Grid>
				<Hidden xlDown>
					<Grid item xs={12} sm={4} md={4}>
						<Box display='flex' alignItems='center'>
							<Button variant='outlined' color='secondary' size='large'>
								Cotação
							</Button>
						</Box>
					</Grid>

					<Grid item xs={12} sm={4} md={4}>
						<Box display='flex' alignItems='center'>
							<Button variant='outlined' color='secondary' size='large'>
								Offshore
							</Button>
						</Box>
					</Grid>
				</Hidden>
			</Grid>

			<Grid item xs={12} sm={4} md={8} alignItems='center'>
				<Hidden xlDown>
					<Box display='flex' alignItems='center' justifyContent={(matSm) ? 'flex-end' : 'flex-start'}>
						<Button variant='contained' color='secondary' size='large' aria-controls="simple-menu" id='menuToggle' aria-haspopup="true" onClick={toggle}>
							Menu
						</Button>
					</Box>
				</Hidden>
				<Menu
					id="simple-menu"
					anchorEl={() => document.querySelector('#menuToggle') || document.createElement('span')}
					keepMounted
					open={menuToggle}
					onClose={() => setMenuToogle(false)}
				>
					<MenuItem onClick={toggle}>Profile</MenuItem>
					<MenuItem onClick={toggle}>My account</MenuItem>
					<MenuItem onClick={toggle}>Logout</MenuItem>
				</Menu>
			</Grid>
		</Grid>
	)
}

const useStyles = makeStyles(theme => ({
	MenuVis: {
		background: theme.palette.primary.main,
		boxShadow: theme.shadows[2],
		transition: '0.5s ease'
	},

	MenuIvi: {
		background: 'transparent',
		boxShadow: theme.shadows[0],
		transition: '0.5s ease'
	}
}))

export default Header;